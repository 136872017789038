<template>
    <div class="page-table scrollable only-y mainDiv">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.yonetici.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh" class="mr-5"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="bottom">
                    <el-button v-on:click="routeYonetici('Yönetici Tanımla')" size="mini" type="text" icon="el-icon-plus"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
        :element-loading-text='loading ? $t("src.views.apps.yonetici.liste.listLoading") : $t("src.views.apps.yonetici.liste.stateLoading")'
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
           <el-row class="mt-0 mb-10">
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-account mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.yonetici.liste.aktifYonetici")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button label="0">
                                <i class="mdi mdi-account-remove mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition  name="slide-fade"><label v-if="selectIcon==='0'">{{$t("src.views.apps.yonetici.liste.pasifYonetici")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>
           </el-row>

            <el-table :data="yoneticiListesi" style="width: 100%" border stripe>
                <el-table-column
                    :label='$t("src.views.apps.genel.sira")'
                    type="index"
                    :index="indexMethod"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="ID"
                    :label='$t("src.views.apps.yonetici.liste.table.yoneticiID")'
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="adSoyad"
                    :label='$t("src.views.apps.yonetici.liste.table.adSoyad")'
                    width="200">
                    <template slot-scope="prop">
                        <div class="new-line-text">
                            {{prop.row.adSoyad}}  
                        </div>
                    </template>
                </el-table-column>
                
                <el-table-column
                    prop="yoneticiAdi"
                    :label='$t("src.views.apps.yonetici.liste.table.userName")'
                    width="auto">
                </el-table-column>
               
                <el-table-column
                    :label='$t("src.views.apps.yonetici.liste.table.yetkiTuru")'
                    width="auto"
                    prop="yetkiTuru">
                    <template slot-scope="prop">
                        <p v-if="prop.row.rol === '1'">{{$t("src.views.apps.yonetici.liste.table.yetkiRol1")}}</p>
                        <p v-if="prop.row.rol === '2'">{{$t("src.views.apps.yonetici.liste.table.yetkiRol2")}}</p>
                        <p v-if="prop.row.rol === '3'">Listeleme Yetkisi</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cepTel"
                    :label='$t("src.views.apps.yonetici.liste.table.telefon")'
                    width="auto">
                </el-table-column>
                <el-table-column
                    prop="dahiliTel"
                    :label='$t("src.views.apps.yonetici.liste.table.dahili")'
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="sabitTel"
                    :label='$t("src.views.apps.yonetici.liste.table.sabit")'
                    width="150">
                </el-table-column>
                <el-table-column
                    :label='$t("src.views.apps.genel.islem")'
                    align="right"
                    width="180">
                    <template slot-scope="scope">
                        <el-tooltip :content='$t("src.views.apps.genel.detay")' :open-delay="500" placement="bottom">
                            <el-button
                                v-on:click="yoneticiDetay(scope.row)"
                                type="text"
                                size="mini"
                                icon="mdi mdi-information-variant mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.genel.duzenle")' :open-delay="500" placement="bottom">
                            <el-button
                                v-on:click="routeYonetici('Yönetici Güncelle',scope.row)"
                                type="text"
                                class="mr-10" style="color: #f7ba2a"
                                icon="mdi mdi-circle-edit-outline mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.genel.pasifKaydet")' :open-delay="500" placement="bottom" v-if="selectIcon == 1">
                            <el-button
                                class="buttonDel"
                                @click="yoneticiDurumChange(scope.row, 0)"
                                type="text"
                                icon="mdi mdi-trash-can mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.genel.aktifGuncelle")' :open-delay="500" placement="bottom"  v-if="selectIcon == 0">
                            <el-button
                                class="buttonDel"
                                @click="yoneticiDurumChange(scope.row, 1)"
                                type="text"
                                icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
            </el-pagination>
        </div>

        <user-dialog v-if="dialogVisibleYonetici" :dialogvisible.sync="dialogVisibleYonetici" :userdata="userDataDemirag"></user-dialog>
    </div>
</template>

<script>
    import yoneticiService from '../../../WSProvider/YoneticiService'
    import UserDialog from '@/components/UserDialog'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import jwt_decode from "jwt-decode"
    import EventBus from "@/components/event-bus";
    

    let userDataDemirag = "";
    export default {
        name: "YoneticiListesi",
        components: {
            UserDialog,
        },
        mounted() {
            const self = this;

            this.getYoneticiCount();
            this.getFirmaYoneticiList();

            EventBus.$on("yoneticiList", function(bool){
                if(bool){
                    self.page = 1;
                    self.postPage = 0;
                    self.getFirmaYoneticiList();
                    self.getYoneticiCount();
                }
            })
        },

        watch: {
            page(val) {
                this.postPage = (val - 1) * this.sizes;
            },
            sehir(newValue){
                if(newValue === "") this.musteriForm.ilce = ""
            }
        },

        data() {
            return {
                stateLoading: false,
                loading: false,
                page: 0,
                sizes: 10,
                postPage: 0,
                total: null,
                selectIcon: '1',
                radio: '1',
                dialogVisibleYonetici: false,
                userDataDemirag: {},
                selection: '',
                yoneticiListesi: [],
            }
        },
        methods: {
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getFirmaYoneticiList();
                this.getYoneticiCount();
            },

            yoneticiDetay(scope) {
                this.userDataDemirag = scope;
                this.dialogVisibleYonetici = true;
            },

            handleChange(event) {
                this.selectIcon = event;
                this.postPage = 0;
                this.page = 1
                this.getFirmaYoneticiList();
                this.getYoneticiCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            getFirmaYoneticiList() {
                try{
                    this.loading = true;
                    yoneticiService.yoneticiListe(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            this.yoneticiListesi = response.data
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.yoneticiListesi = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            getYoneticiCount(){
                try{
                    yoneticiService.yoneticiCount(this.selectIcon).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            this.total = parseInt(response.data)
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0;
                            }
                        }
                    })
                }catch(e){

                }
            },

            yoneticiDurumChange(selection, durum) {
                this.openConfirmDialog("Yöneticinin durumunu güncellemek istediğinizden emin misiniz?").then(() => {
                    try{
                        this.stateLoading = true;
                        if(durum === 0){
                            yoneticiService.yoneticiPasifYap(selection.ID).then(response => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)
                                    notification.Status("success", this, response.msg)
                                    this.getFirmaYoneticiList();
                                    this.getYoneticiCount();
                                }
                                this.stateLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.stateLoading = false;
                            })
                        }
                        else if(durum === 1){
                            this.stateLoading = true;
                            yoneticiService.yoneticiAktifYap(selection.ID).then(response => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)
                                    notification.Status("success", this, response.msg)
                                    this.getFirmaYoneticiList();
                                    this.getYoneticiCount();
                                }
                                this.stateLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.stateLoading = false;
                            })
                        }
                    }catch(e){
                        this.stateLoading = false;
                    }
                })
            },
            
            routeYonetici(title, scope) {
                if (scope) {
                    this.$store.commit('changeYoneticiUpdateData', scope);
                }
                this.routeSayfa(title)
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getFirmaYoneticiList();
                this.getYoneticiCount();
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getFirmaYoneticiList();
                this.getYoneticiCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }

</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .el-radio-button--mini .el-radio-button__inner {
        padding: 0 10px !important;
        font-size: 11px;
        border-radius: 0;
    }

    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>